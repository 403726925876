import React from 'react';

import { Typography } from 'shared/components/typography/Typography';
import { Separator } from 'shared/components/separator/Separator';
import { StyledPaper, Header } from './Section.styles';
import { SectionProps } from './Section.types';

export const Section: React.FC<SectionProps> = ({ children, title, subtitle }) => {
  return (
    <>
      {(title || subtitle) && (
        <Header>
          {!!title && <Typography variant="h3">{title}</Typography>}
          {!!subtitle && <Typography variant="caption">{subtitle}</Typography>}
        </Header>
      )}
      <Separator height={15} />
      <StyledPaper>{children}</StyledPaper>
    </>
  );
};

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { VirtualGraduationTemplate } from './template/VirtualGraduationTemplate';
import { UploadContainer } from './upload/UploadContainer';
import { Congratulations } from './congratulations/Congratulations';

export const VirtualGraduation = () => {
  return (
    <VirtualGraduationTemplate>
      <Switch>
        <Route path="/congratulations" component={Congratulations} />
        <Route path="/invalid-url">Your link is invalid</Route>
        <Route path="/something-went-wrong">Something went wrong. Please contact our support</Route>
        <Route path="/:placeholderId" component={UploadContainer} />
        <Redirect to="/invalid-url" />
      </Switch>
    </VirtualGraduationTemplate>
  );
};

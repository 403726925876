import { styled } from '@material-ui/core';

import { Paper } from 'shared/components/paper/Paper';

const padding = 28;
export const StyledPaper = styled(Paper)({
  padding,
});

export const Header = styled('div')({
  padding: `0 ${padding}px`,
});

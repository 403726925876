import { styled } from '@material-ui/core';

import { Typography } from 'shared/components/typography/Typography';

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

export const Header = styled(Typography)(({ theme: { breakpoints } }) => ({
  [breakpoints.down('sm')]: {
    fontSize: 34,
  },
}));

export const Content = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.gray.main,
}));

import React from 'react';

import { Separator } from 'shared/components/separator/Separator';
import { ScrollToTop } from 'shared/components/scrollToTop/ScrollToTop';
import { Header } from './header/Header';
import { Footer } from './footer/Footer';
import { StyledContainer } from './VirtualGraduationTemplate.styles';

export const VirtualGraduationTemplate: React.FC = ({ children }) => {
  return (
    <>
      <ScrollToTop />
      <StyledContainer>
        <Header />
        <Separator height={50} />
        {children}
      </StyledContainer>
      <Separator height={50} />
      <Footer />
    </>
  );
};

import { parseUrl, stringifyUrl } from 'query-string';

import { Action } from 'api/types';

export const requestSecretInterceptor = (secret: string) => () => async (action: Action) => {
  if (action.skipAuth) {
    return action;
  }

  const { url, query } = parseUrl(action.endpoint);

  return {
    ...action,
    endpoint: stringifyUrl({ url, query: { ...query, s: secret } }),
  };
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  loader: {
    textAlign: 'center',
  },
  wrapper: {
    padding: '10px 30px',
  },
});

import { CreateUploadParticipantDataRequest, CreateFetchParticipantData } from './participant.types';

export const createUploadParticipantData: CreateUploadParticipantDataRequest = ({ placeholderId, ...body }) => ({
  endpoint: `/marching-orders/${placeholderId}`,
  method: 'PATCH',
  body,
});

export const createFetchParticipantData: CreateFetchParticipantData = participantId => ({
  endpoint: `/virtual-graduation/participant/${participantId}`,
  method: 'GET',
});

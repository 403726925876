import { useEffect, useCallback } from 'react';

export const useOnClickOutside = (
  callback: () => void,
  ref: HTMLElement | undefined | null,
  events: string[] = ['click'],
) => {
  const handleEvent = useCallback(
    (event: any) => {
      if (ref && !ref.contains(event.target)) {
        callback();
      }
    },
    [callback, ref],
  );

  useEffect(() => {
    if (ref) {
      events.forEach(eventType => document.addEventListener(eventType, handleEvent));
    }

    return () => {
      events.forEach(eventType => document.removeEventListener(eventType, handleEvent));
    };
  }, [ref, events, handleEvent]);
};

import React from 'react';

import { Section } from '../../section/Section';

import { Image } from 'shared/components/image/Image';
import { Separator } from 'shared/components/separator/Separator';
import snapcode from 'assets/snapcode.svg';
import { Content, StyledLink, LogoWrapper } from './Guide.styles';

export const Guide = () => {
  return (
    <Section title="virtual_graduation.upload.guide.title" subtitle="virtual_graduation.upload.guide.subtitle">
      <Content variant="body2" align="center">
        virtual_graduation.upload.guide.content
      </Content>
      <Separator height={30} />
      <LogoWrapper>
        <Content variant="body2" align="center">
          virtual_graduation.upload.guide.snapchat
        </Content>
        <Separator height={15} />
        <StyledLink href={process.env.REACT_APP_SNAPCHAT_URL} target="_blank" rel="noopener noreferrer">
          <Image alt="StageClip – Share life's big moments" src={snapcode} />
        </StyledLink>
      </LogoWrapper>
    </Section>
  );
};

import React from 'react';

import { SimplePage } from '../simplePage/SimplePage';

import icon from 'assets/icons/success.svg';

export const Congratulations = () => (
  <SimplePage
    header="virtual_graduation.congratulations.header"
    content="virtual_graduation.congratulations.content"
    icon={icon}
  />
);

import React from 'react';

import { Section } from '../../section/Section';

import { Specification } from 'shared/components/specification/Specification';
import { Option } from 'shared/components/specification/Specification.types';
import { GridContainer, GridItem } from 'shared/components/grid';
import { FileDropZoneField } from 'shared/components/controls/fileDropZone/FileDropZoneField';
import { Separator } from 'shared/components/separator/Separator';
import { required } from 'shared/validators';

const allowedExtensions = ['mp4', 'mov'];

const rules: Option[] = [
  {
    icon: 'InsertDriveFile',
    label: 'virtual_graduation.upload.video.specification.file_type',
    content: allowedExtensions.join(', '),
  },
  { icon: 'PhotoSizeSelectSmall', label: 'virtual_graduation.upload.video.specification.max_size', content: '100MB' },
  { icon: 'Alarm', label: 'virtual_graduation.upload.video.specification.max_duration', content: '10s' },
];

export const Video = () => {
  return (
    <Section title="virtual_graduation.upload.video.title" subtitle="virtual_graduation.upload.video.subtitle">
      <GridContainer>
        <GridItem xs={12} md={8} lg={5}>
          <Specification options={rules} title="virtual_graduation.upload.video.specification.title" />
        </GridItem>
      </GridContainer>
      <Separator height={25} />
      <FileDropZoneField
        name="file"
        accept={allowedExtensions}
        maxSize="100 MB"
        maxDuration={10}
        validate={[required()]}
      />
    </Section>
  );
};

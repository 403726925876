import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { StyledFormHelperText } from '../controlBase/formHelperText/FormHelperText.styles';
import { BlobVideo } from '../../blobVideo/BlobVideo';
import { Image } from '../../image/Image';
import { Typography } from '../../typography/Typography';
import { Button } from '../../button/Button';
import { Loader } from '../../loader/Loader';
import { BlobImage } from '../../blobImage/BlobImage';
import { BlobPlayer } from '../../blobPlayer/BlobPlayer';

import { useDragAndDropState } from 'shared/hooks';
import { getExtension, defaultExtensions, isNotAllowedFile } from './FileDropZone.utils';
import { getUrlAsFile, getFileType } from 'shared/utils';
import { Wrapper, DropZone, TextWrapper, Input, useStyles } from './FileDropZone.styles';
import { FileDropZoneProps, Error } from './FileDropZone.types';
import uploadIcon from 'assets/icons/upload_bg.svg';
import { isMobile } from 'shared/utils/isMobile/isMobile';

const defaultPreviews = {
  image: BlobImage,
  video: BlobVideo,
  audio: ({ src }: { src: File }) => <BlobPlayer src={src} title={src.name === 'filename' ? '' : src.name} />,
};

export const FileDropZone: React.FC<FileDropZoneProps> = ({
  name,
  onChange,
  accept = defaultExtensions,
  value,
  onFocus,
  onBlur,
  helperText,
  previews = {},
  ratio,
  disabled,
  hideUploadButton = false,
}) => {
  const dragState = useDragAndDropState();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inArea, setInArea] = useState(false);
  const [storage, setStorage] = useState<File | null>(null);
  const [error, setError] = useState<Error>(null);
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();

  const drag = disabled ? false : dragState;

  const styles = useStyles();

  const dropZoneClassNames = clsx({
    [styles.active]: drag,
    [styles.inArea]: inArea,
    [styles.error]: !!error || !!helperText,
    [styles.disabled]: disabled,
  });

  const previewOptions = {
    ...defaultPreviews,
    ...previews,
  };

  useEffect(() => {
    const readUrlAsFile = async (url: string) => {
      const file = await getUrlAsFile(url);

      setStorage(file);
    };

    if (typeof value === 'string' && value.length > 0) {
      readUrlAsFile(value).finally(() => setLoading(false));

      setLoading(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleDragEnter = (event: React.DragEvent) => {
    const { items } = event.dataTransfer;

    if (isNotAllowedFile(items, accept)) {
      setError([{ id: 'control.file_drop_zone.not_allowed' }, { formats: accept?.join(', ') }]);
    }

    if (!inArea) {
      setInArea(true);
    }
  };

  const handleDragLeave = () => {
    if (inArea) {
      setInArea(false);

      if (error) {
        setError(null);
      }
    }
  };

  const handleDrop = (event: React.DragEvent) => {
    if (event.dataTransfer && event.dataTransfer.files.length !== 0 && event.dataTransfer.files[0] && !error) {
      const nextFile = event.dataTransfer.files[0];
      setStorage(nextFile);

      if (onChange) {
        onChange(nextFile);
      }
    }

    if (inArea) {
      setInArea(false);
      setError(null);
    }
  };

  const handleButtonClick = () => {
    const input = inputRef.current;

    if (input) {
      input.click();

      if (onFocus) {
        onFocus();
      }
    }
  };

  const handleOnChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;

    if (files && files.length !== 0 && files[0]) {
      const nextFile = files[0];

      setStorage(nextFile);

      if (onChange) {
        onChange(nextFile);
      }
    }

    if (onBlur) {
      onBlur();
    }
  };

  useEffect(() => {
    if (drag) {
      if (inArea) {
        return onFocus && onFocus();
      }

      return onBlur && onBlur();
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inArea]);

  const showImagePreview = !drag && !loading;
  const showDropZoneContent = !storage || drag;
  const type = storage ? getFileType(storage) : null;
  const Preview = (type && previewOptions[type]) || 'div';

  return (
    <Wrapper>
      <DropZone
        className={dropZoneClassNames}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {loading && <Loader />}
        {storage && showImagePreview && <Preview alt="new logo" src={storage} ratio={ratio} />}

        {showDropZoneContent && (
          <>
            <Image src={uploadIcon} alt="clip_template.variant.upload.title" />
            <TextWrapper>
              {!isMobile && (
                <Typography variant="body1">
                  {drag ? 'control.file_drop_zone.drop_here' : 'control.file_drop_zone.text'}
                </Typography>
              )}
              {!drag && (
                <Button variant="text" color="primary" onClick={handleButtonClick}>
                  control.file_drop_zone.browse
                </Button>
              )}
            </TextWrapper>
          </>
        )}
        <Input name={name} type="file" onChange={handleOnChange} ref={inputRef} accept={getExtension(accept)} />
      </DropZone>
      <StyledFormHelperText>{error ? formatMessage(...error) : helperText || <span>&nbsp;</span>}</StyledFormHelperText>
      {!hideUploadButton && !showDropZoneContent && (
        <Button
          variant="text"
          color="primary"
          onClick={handleButtonClick}
          size="small"
          className={styles.browse}
          disabled={disabled}
        >
          control.file_drop_zone.upload_file
        </Button>
      )}
    </Wrapper>
  );
};

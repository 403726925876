import { styled, Link } from '@material-ui/core';

import { Typography } from 'shared/components/typography/Typography';

export const Content = styled(Typography)(({ theme: { palette } }) => ({ color: palette.gray.main }));

export const LogoWrapper = styled(`div`)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const StyledLink = styled(Link)({
  maxWidth: '176px',
});

import React from 'react';

import { Section } from '../section/Section';

import { Image } from 'shared/components/image/Image';
import { Separator } from 'shared/components/separator/Separator';
import { Wrapper, Header, Content } from './SimplePage.styles';
import { SimplePageProps } from './SimplePage.types';

export const SimplePage = ({ icon, header, content }: SimplePageProps) => {
  return (
    <Section>
      <Wrapper>
        <Image alt="" src={icon} />
        <Separator height={30} />
        <Header variant="h1" align="center">
          {header}
        </Header>
        <Separator height={15} />
        <Content variant="body1" align="center">
          {content}
        </Content>
      </Wrapper>
    </Section>
  );
};

import React from 'react';
import { useMutation } from 'react-fetching-library';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { createUploadParticipantData } from '../api/participant/participant.actions';

import { Upload } from './Upload';
import { handleOnSubmit } from 'shared/utils/handleOnSubmit/handleOnSubmit';

export const UploadContainer = () => {
  const { mutate } = useMutation(createUploadParticipantData);
  const { push } = useHistory();
  const {
    params: { placeholderId },
  } = useRouteMatch<{ placeholderId: string }>();

  const onSubmit = handleOnSubmit({
    action: values => mutate({ ...values, placeholderId }),
    successCallback: () => push('/congratulations'),
  });

  return <Upload onSubmit={onSubmit} />;
};

import React from 'react';
import { useFormState } from 'react-final-form';

import { useTranslations } from 'shared/hooks';
import { Loader } from 'shared/components';
import { useDisabled } from './Button.utils';
import { ButtonProps } from './Button.types';
import { StyledButton, LoaderWrapper } from './Button.styles';

const ButtonBase: React.FC<ButtonProps> = React.forwardRef(({ loading, children, disabled, ...restProps }, ref) => {
  const { message } = useTranslations(children);

  const isDisabled = disabled || loading;

  return (
    <StyledButton {...restProps} disabled={isDisabled} ref={ref}>
      {loading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      {message}
    </StyledButton>
  );
});

const SubmitButton: React.FC<ButtonProps> = React.forwardRef((props, ref) => {
  const disabled = useDisabled(props);

  const { submitting } = useFormState({
    subscription: { submitting: true },
  });

  return <ButtonBase {...props} disabled={disabled} loading={submitting} ref={ref} />;
});

export const Button: React.FC<ButtonProps> = React.forwardRef((props, ref) => {
  const { type } = props;

  if (type === 'submit') {
    return <SubmitButton {...props} ref={ref} />;
  }

  return <ButtonBase {...props} />;
});

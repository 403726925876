import React from 'react';

import { Separator } from '../../separator/Separator';

import { Footer } from './DialogFooter.styles';

export const DialogFooter: React.FC = ({ children }) => (
  <>
    <Separator height={20} />
    <Footer data-testid="dialog-footer">{children}</Footer>
  </>
);

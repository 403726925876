import React from 'react';

import { Wrapper, Logo } from './Header.styles';
import clippingPlatformLogo from 'assets/clipping_platform_logo.png';

export const Header = () => {
  return (
    <Wrapper>
      <Logo alt="StageClip – Share life's big moments" src={clippingPlatformLogo} />
    </Wrapper>
  );
};

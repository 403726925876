import React from 'react';

import { Typography } from '../../typography/Typography';
import { Separator } from '../../separator/Separator';

export const DialogTitle: React.FC = ({ children }) => (
  <header data-testid="dialog-title">
    <Typography variant="h4">{children}</Typography>
    <Separator height={20} />
  </header>
);

import React, { useMemo } from 'react';
import { ClientContextProvider, createClient } from 'react-fetching-library';

import { requestSecretInterceptor, requestHostInterceptor, requestBodyInterceptor } from 'api/interceptors';
import { useParticipantData } from 'app/virtualGraduation/participantContext/participantContext';

export const ClientContextController: React.FC = ({ children }) => {
  const { secret } = useParticipantData();

  const client = useMemo(() => {
    return createClient({
      requestInterceptors: [requestSecretInterceptor(secret), requestHostInterceptor, requestBodyInterceptor],
    });
  }, [secret]);

  return <ClientContextProvider client={client}>{children}</ClientContextProvider>;
};

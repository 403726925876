import React from 'react';
import '@formatjs/intl-pluralrules/dist/locale-data/en';

import { Theme, Providers } from 'app/core';
import { VirtualGraduation } from './virtualGraduation/VirtualGraduation';
import { ParticipantDataProvider } from './virtualGraduation/participantContext/participantContext';

const App: React.FC = () => {
  return (
    <Theme>
      <ParticipantDataProvider>
        <Providers>
          <VirtualGraduation />
        </Providers>
      </ParticipantDataProvider>
    </Theme>
  );
};

export default App;

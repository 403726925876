import { Toolbar as MaterialToolbar, styled, makeStyles } from '@material-ui/core';

export const StyledToolbar = styled(MaterialToolbar)({
  flexDirection: 'column',
  paddingLeft: 0,
});

export const useStyles = makeStyles({
  row: {
    width: '100%',
    display: 'flex',
  },
  options: {
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

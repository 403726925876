import React from 'react';

import { Guide } from './guide/Guide';
import { Video } from './video/video';
import { Separator } from 'shared/components/separator/Separator';
import { Button } from 'shared/components/button/Button';
import { GridContainer, GridItem } from 'shared/components/grid';
import { Form } from 'shared/components/form/Form';
import { Disclaimer } from './Upload.styles';
import { UploadProps, FormValues } from './Upload.types';

export const Upload = ({ onSubmit }: UploadProps) => {
  return (
    <>
      <Guide />
      <Separator height={50} />
      <Form<FormValues>
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Video />
              <Separator height={50} />
              <GridContainer justify="center">
                <GridItem xs={12} md={8}>
                  <Disclaimer variant="body2">virtual_graduation.upload.disclaimer</Disclaimer>
                </GridItem>
              </GridContainer>
              <Separator height={50} />
              <GridContainer justify="center">
                <GridItem xs={12} md={8} lg={5}>
                  <Button type="submit" variant="contained" color="primary" fullWidth size="large">
                    virtual_graduation.upload.submit
                  </Button>
                </GridItem>
              </GridContainer>
            </form>
          );
        }}
      />
    </>
  );
};

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getFileType } from '../../utils';

import { DurationValidatorOptions } from './duration.validator.types';

export const duration = (config: DurationValidatorOptions) => (value: File) => {
  return new Promise(resolve => {
    if (value && value instanceof File) {
      const { maxDuration } = config;

      const fileType = getFileType(value);

      if (fileType && fileType === 'video') {
        const element = document.createElement('video');
        element.src = URL.createObjectURL(value);
        element.preload = 'metadata';

        element.onloadedmetadata = () => {
          const { duration: videoDuration } = element;

          if (videoDuration > maxDuration) {
            return resolve(<FormattedMessage id="validation.duration" values={{ maxDuration: `${maxDuration}s` }} />);
          }

          return resolve();
        };

        return undefined;
      }
    }

    return resolve();
  });
};

import { updatableContextFactory } from 'shared/utils/contextFactory/updatableContextFactory';
import { ParticipantContext } from './participantContext.types';

const [ParticipantDataProvider, useParticipantContext, useUpdateParticipantData] = updatableContextFactory<
  ParticipantContext
>({} as ParticipantContext);

export const useParticipantData = () => {
  const context = useParticipantContext();

  if (context === undefined) {
    throw new Error('useParticipantData must be used within an ParticipantDataProvider');
  }

  return context;
};

export { ParticipantDataProvider, useUpdateParticipantData };

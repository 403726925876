import { styled, withStyles, Dialog } from '@material-ui/core';

export const StyledDialog = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    minWidth: '30%',
  },
  paper: {
    width: '100%',
  },
})(Dialog);

export const Content = styled('div')({ padding: 24, width: '100%' });

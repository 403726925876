import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { ClientContextController } from 'context/client';
import { LocaleContextController } from 'context/locale';

export const Providers: React.FC = ({ children }) => (
  <LocaleContextController>
    <Router>
      <ClientContextController>{children}</ClientContextController>
    </Router>
  </LocaleContextController>
);

import { styled } from '@material-ui/core';

import { Image } from 'shared/components/image/Image';

const radius = 8;
export const Wrapper = styled('div')(({ theme: { palette } }) => ({
  borderBottomLeftRadius: radius,
  borderBottomRightRadius: radius,
  background: palette.white.main,
  width: '100%',
  padding: 25,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Logo = styled(Image)(({ theme: { breakpoints } }) => ({
  maxWidth: '50%',
  [breakpoints.down('md')]: {
    maxWidth: '75%',
  },
  [breakpoints.up('md')]: {
    maxWidth: '30%',
  },
}));

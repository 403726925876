import { styled, makeStyles } from '@material-ui/core';

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const DropZone = styled('div')(({ theme: { palette } }) => ({
  alignItems: 'center',
  border: `2px dashed ${palette.gray.main}`,
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  maxHeight: 400,
  padding: 20,
  textAlign: 'center',
  boxSizing: 'content-box',
  '& > img': {
    maxHeight: 'inherit',
  },
  '& > video': {
    maxHeight: 'inherit',
  },
}));

export const TextWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Input = styled('input')({
  display: 'none',
  opacity: 0,
});

export const useStyles = makeStyles(({ palette }) => ({
  active: {
    '& > *': {
      pointerEvents: 'none',
    },
  },
  inArea: {
    borderColor: palette.green.main,
  },
  error: {
    borderColor: palette.error.main,
  },
  browse: {
    alignSelf: 'flex-start',
  },
  disabled: {
    '&, & *': {
      'pointer-events': 'none',
    },
  },
}));

import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import { Separator, PageAlert } from 'shared/components';
import { Props } from './Form.types';

export const Form: <T extends any>(props: Props<T>) => React.ReactElement = props => {
  return (
    <FinalForm
      {...props}
      render={formProps => {
        const { submitError, hasSubmitErrors, dirtySinceLastSubmit } = formProps;

        const showError = !dirtySinceLastSubmit && hasSubmitErrors && submitError;

        return (
          <div>
            {showError && (
              <>
                <PageAlert severity="error">{submitError}</PageAlert>
                <Separator height={68} />
              </>
            )}
            {props.render(formProps)}
          </div>
        );
      }}
    />
  );
};

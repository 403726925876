import React, { useCallback, FocusEvent } from 'react';
import { useField } from 'react-final-form';
import { combine } from 'redux-form-validators';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { ConnectControlProps } from './connectControl.types';

const FieldProps = [
  'afterSubmit',
  'beforeSubmit',
  'component',
  'data',
  'defaultValue',
  'format',
  'formatOnBlur',
  'initialValue',
  'isEqual',
  'parse',
  'render',
  'subscription',
  'validate',
  'validateFields',
  'value',
];

export const connectControl = <ComponentProps extends {}, FieldValue = any>(
  Control: React.ComponentType<ComponentProps>,
  overrideProps: (
    props: ConnectControlProps<ComponentProps, FieldValue>,
  ) => ConnectControlProps<ComponentProps, FieldValue> = props => props,
) => (props: ConnectControlProps<ComponentProps, FieldValue>) => {
  const newProps = overrideProps(props);

  const { name, validate = [], onFocus, onBlur, ...restProps } = newProps;

  const {
    input,
    meta: { error, touched, submitError, submitting, dirtySinceLastSubmit },
  } = useField(name, { ...pick(restProps, FieldProps), validate: combine(...validate) });

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLElement>) => {
      if (onFocus) {
        onFocus();
      }

      input.onFocus(event);
    },
    [input, onFocus],
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLElement>) => {
      if (onBlur) {
        onBlur();
      }

      input.onBlur(event);
    },
    [input, onBlur],
  );

  const hasError = touched && (!!error || (!!submitError && !dirtySinceLastSubmit));
  const errorMessage = hasError ? error || submitError : undefined;

  return (
    <Control
      {...(omit(restProps, FieldProps) as ComponentProps)}
      {...input}
      onFocus={handleFocus}
      onBlur={handleBlur}
      error={hasError}
      helperText={errorMessage}
      disabled={restProps.disabled || submitting}
    />
  );
};

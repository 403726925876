import { FileValidatorOptions } from 'redux-form-validators';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import { connectControl } from '../connectControl/connectControl';
import { ConnectControlProps } from '../connectControl/connectControl.types';

import { file, resolution as resolutionValidator, duration as durationValidator } from 'shared/validators';
import { FileDropZoneFieldProps } from './FileDropZone.types';
import { getExtension, defaultExtensions } from './FileDropZone.utils';
import { FileDropZone } from './FileDropZone';

const fileValidator = (options?: FileValidatorOptions) => (value: any, allValues?: any, props?: any) => {
  if (typeof value === 'string') {
    return undefined;
  }

  return file(options)(value, allValues, props);
};

const getValidators = (props: ConnectControlProps<FileDropZoneFieldProps>) => {
  const { validate = [], maxSize = '10 MB', accept = defaultExtensions, resolution, maxDuration } = props;
  const extensions = getExtension(accept);

  const validators = [...validate];

  // size validation
  if ([maxSize, extensions].some(value => typeof value !== 'undefined')) {
    const config = {
      accept: extensions,
      maxSize,
    };

    validators.push(fileValidator(omitBy(config, isNil)));
  }

  // resolution validation
  if (resolution) {
    validators.push(resolutionValidator(resolution));
  }

  // duration validator
  if (maxDuration) {
    validators.push(durationValidator({ maxDuration }));
  }

  return { validate: validators };
};

export const FileDropZoneField = connectControl<FileDropZoneFieldProps>(FileDropZone, props => ({
  ...props,
  ...getValidators(props),
}));
